import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import { Section } from '@styles/global.styled'
import MediaHubSearchFrom from '@components/MediaHub/MediaHubSearchForm'
import React from 'react'
import FeaturedArticleHero from '@global/Blocks/FeaturedArticleHero'
import { apiFieldsQuery } from '@lib/variables'
import { getChildPages } from '@lib/api-services/cmsService'

const MediaHubLandingPage = ({ latestArticle, pageData }) => {
  return (
    <>
      {latestArticle && (
        <Section>
          <FeaturedArticleHero
            heading={latestArticle.title}
            description={latestArticle.meta?.meta_description}
            image={latestArticle.header_image?.url}
            link={{ text: 'Read More', url: latestArticle.meta?.path }}
          />
        </Section>
      )}

      <Section>
        <MediaHubSearchFrom
          navigationItems={pageData?.navigation_items}
          hideSearch
        />
      </Section>

      <WagtailBlocksSection blocks={pageData.content_blocks} />
      {
        // instagram feed
        pageData.instagram_feed_code && (
          <div className='block-component'>
            <div className='o-wrapper '>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.instagram_feed_code,
                }}
              />
            </div>
          </div>
        )
      }
      {
        // facebook feed
        pageData.facebook_feed_code && (
          <div className='block-component'>
            <div className='o-wrapper '>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.facebook_feed_code,
                }}
              />
            </div>
          </div>
        )
      }
    </>
  )
}

MediaHubLandingPage.getInitialProps = async (ctx, pageData) => {
  const queryParams = {
    type: 'blog.BlogPost',
    parentId: null,
    limit: 1,
    page: 1,
    params: {
      order: '-date',
    },
    fields: apiFieldsQuery.BlogPost,
  }

  if (pageData.meta.office_ancestor?.id) {
    queryParams.params.office_id = pageData.meta.office_ancestor.id
  }

  const latestArticle = await getChildPages(
    queryParams.type,
    queryParams.parentId,
    queryParams.limit,
    queryParams.page,
    queryParams.params,
    queryParams.fields
  )

  return {
    latestArticle: latestArticle?.items?.[0],
    pageData,
  }
}

export default MediaHubLandingPage
