import React, { useEffect, useState } from 'react'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import { getOffices } from '@lib/api-services/officesService'
import { UrlBuilder } from '@lib/helpers'
import OfficeSearch from '@components/Offices/OfficeSearchForm'
import { Container } from '@styles/global.styled'
import Breadcrumbs from '@global/Breadcrumbs'
import OfficeResults from '@components/Offices/OfficeResults'
import MobileNavbar from '@global/MobileNavbar'

const getBreadcrumbs = (slug, params) => {
  const breadcrumbs = [
    {
      label: 'Find an Office',
      url: `/${slug}/`,
    },
  ]

  if (params.search) {
    breadcrumbs.push({
      label: params.search,
      url: UrlBuilder(`/${slug}/`, { keyword: params.search }),
    })
  }

  return [
    ...breadcrumbs,
    {
      label: 'Search Results',
    },
  ]
}

const formatListingMapData = (res) => {
  let results = []
  res.results.forEach((it) => {
    if (it.longitude && it.latitude) {
      results.push({
        ...it,
        location: {
          type: 'Point',
          coordinates: [it.longitude, it.latitude],
        },
      })

      if (it.extra_locations.length) {
        it.extra_locations.forEach((location) => {
          if (location.longitude && location.latitude) {
            results.push({
              ...it,
              ...location,
              isExtraLocation: true,
              location: {
                type: 'Point',
                coordinates: [location.longitude, location.latitude],
              },
            })
          }
        })
      }
    }
  })

  return results
}

const OfficeIndexPage = (props) => {
  const { pageData, slug, title } = props
  const [isLoading, setIsLoading] = useState(false)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    search: '',
    view: props.queryParams.view ?? 'grid',
  })
  const [offices, setOffices] = useState({ ...props.offices })

  const getOfficeList = async ({ search }) => {
    setQueryParams({ ...queryParams, page: 1, search })
    try {
      setIsLoading(true)
      const itemPerPage = queryParams.view === 'map' ? 500 : 15
      const res = await getOffices({ page: 1, search, per_page: itemPerPage })

      if (queryParams.view === 'map') {
        const results = formatListingMapData(res)
        res.results = results
        res.count = results.length
      }
      setOffices({ ...res })
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setOffices({ ...props.offices })
  }, [props.offices])

  useEffect(() => {
    setQueryParams({
      page: 1,
      search: '',
      view: props.queryParams.view,
    })
  }, [props.queryParams.view])

  return (
    <>
      <Container>
        <Breadcrumbs breadcrumbs={getBreadcrumbs(slug, queryParams)} />
        <OfficeSearch
          title={title}
          onSubmit={getOfficeList}
          initialValues={{ keyword: queryParams.search ?? '' }}
        />
        <OfficeResults
          offices={offices}
          getOffices={getOffices}
          setOffices={setOffices}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
        <WagtailBlocksSection blocks={pageData.content_blocks} />
      </Container>
      <MobileNavbar currentPath={'offices'} />
    </>
  )
}

OfficeIndexPage.getInitialProps = async (ctx, pageData) => {
  const view = ctx.query.view ?? 'grid'

  const queryParams = {
    view: ctx.query.view ?? 'grid',
    per_page: 15,
    page: 1,
  }

  if (view === 'map') {
    queryParams.per_page = 500
  }

  const offices = await getOffices(queryParams)

  if (view === 'map') {
    const results = formatListingMapData(offices)
    offices.results = results
    offices.count = results.length
  }

  return { queryParams, offices, title: pageData.title }
}

export default OfficeIndexPage
