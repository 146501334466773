import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'


// export const SelectedFilters = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 0 28px;
//   color: ${COLORS.secondary};
//   font-family: ${FONTS.medium};
//   cursor: pointer;
//
//   svg {
//     margin-left: 8px;
//   }
// `

export const FormGroup = styled.div`
  padding: 16px 24px;
  background-color: ${COLORS.nature[95]};
  display: flex;
  align-items: center;
  border-radius: 0 0 30px 0;
  gap: 1rem;
  
  @media ${DEVICES.mobile} {
    border-radius: 0;
  }
`

export const FilterOptions = styled.div`
  background-color: ${COLORS.nature[95]};
  display: flex;
`

export const FilterOptionItem = styled.div`
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  padding: 12px 24px 10px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  span:last-child {
    display: none;
  }

  @media ${DEVICES.mobile} {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 12px 16px;

    span:first-child {
      display: none;
    }

    span:last-child {
      display: block;
    }
  }

  ${({ active }) =>
    active &&
    css`
      color: ${COLORS.primary};

      @media ${DEVICES.mobile} {
        border-color: ${COLORS.primary};
      }
    `}
`

export const MediaHubSearchFrom = styled.div`
  position: relative;
  width: 100%;
  max-width: 830px;
  z-index: 9;

  @media ${DEVICES.mobile} {
    ${FormGroup} {
      padding: 16px;
    }

    ${FilterOptions} {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    button {
      display: none;
    }
  }

  ${({ hideSearch }) =>
    hideSearch &&
    css`
      display: inline-block;
      max-width: unset;
      width: unset;
    `}
`

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 52px;
  color: ${COLORS.secondary};
  flex: 1;
  gap: 3rem;

  > div {
    margin-bottom: unset;
    width: 100%;

    input {
      max-height: 52px;
      padding: 14px 20px 14px 60px;
      transition: .3s ease;
      font-size: 16px;
      color: ${COLORS.secondary};

      &::placeholder {
        color: ${COLORS.secondary};
      }
    }
  }

  > svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    width: 24px;
    height: 24px;
  }
`

export const MediaHubSearchWrap = styled.div`
    .media-hub-container {
      @media ${DEVICES.mobile} {
        padding: 0;
      }
    }
`
