import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import EnquiryForm from '@global/Form/EnquiryForm'
import OfficeCard from '@global/OfficeCard'
import LoadmoreInfo from '@global/SearchResults/LoadmoreInfo'
import NoResults from '@global/SearchResults/NoResults'
import SearchResultsCount from '@global/SearchResults/SearchResultsCount'
import SearchResultsTabs from '@global/SearchResults/SearchResultsTabs'
import { UrlBuilder, googleLocationLink } from '@lib/helpers'
import { Router } from '@routes'
import { Col, Row } from '@styles/global.styled'
import React, { useState } from 'react'
import dynamic from 'next/dynamic'

const ResultsMap = dynamic(() => import('@global/SearchResults/ResultsMap'), {
  ssr: false,
})

const OfficeResults = ({
  offices,
  getOffices,
  setOffices,
  setQueryParams,
  queryParams,
}) => {
  const { setShowEnquiryForm } = useEnquiryFormContext()
  const [selectedOffice, setSelectedOffice] = useState(null)

  const onViewChange = (view) => {
    Router.pushRoute(
      UrlBuilder(`/${Router.router.state.query.slug}/`, { view })
    )
  }

  const onClickEnquire = (office) => {
    setSelectedOffice(office)
    setShowEnquiryForm(true)
  }

  return (
    <SearchResultsTabs activeTab={queryParams.view} onViewChange={onViewChange}>
      <SearchResultsCount
        total={offices.count}
        numberShowing={
          queryParams.view === 'map' ? false : queryParams.page * 15
        } // Hide on map view
      />
      {offices.count > 0 ? (
        queryParams.view === 'map' ? (
          <ResultsMap
            items={offices.results}
            renderPopup={(office) => {
              return (
                <OfficeCard
                  mapResults
                  {...office}
                  link={office.path}
                  name={`${office.title}${
                    !office.isExtraLocation ? ` ${office.office_name}` : ''
                  }`}
                  profileLink={{
                    label: 'office profile',
                    link: office.path,
                  }}
                  emailLabel='Email'
                  img={{
                    url: office.results_image_large,
                    alt: `Photo of ${office.title}`,
                  }}
                  directionsLink={{
                    label: 'get directions',
                    link: googleLocationLink(office),
                  }}
                />
              )
            }}
          />
        ) : (
          <React.Fragment>
            <Row gutter={15}>
              {offices.results.map((office, key) => (
                <Col key={`office-${key}`} md={4}>
                  <OfficeCard
                    {...office}
                    link={office.path}
                    name={office.title}
                    profileLink={{
                      label: 'office profile',
                      link: office.path,
                    }}
                    emailLabel='Email'
                    img={{
                      url: office.results_image_large,
                      alt: `Photo of ${office.title}`,
                    }}
                    directionsLink={{
                      label: 'get directions',
                      link: googleLocationLink(office),
                    }}
                    onClickEnquire={onClickEnquire}
                  />
                </Col>
              ))}
            </Row>
            <LoadmoreInfo
              total={offices.count}
              numberShowing={queryParams.page * 15}
              queryParams={queryParams}
              apiCall={getOffices}
              page={queryParams.page}
              onSuccess={(data, newPage) => {
                const newResults = offices.results.concat(data)
                setOffices({ ...offices, results: newResults })
                setQueryParams({ ...queryParams, page: newPage })
              }}
            />
          </React.Fragment>
        )
      ) : (
        <NoResults
          title='Sorry, we couldn’t find any projects matching your search.'
          description='Try searching with another term, or extending your suburb reach.'
        />
      )}
      {selectedOffice && (
        <EnquiryForm
          type='office'
          officePageTitle={`Barry Plant ${selectedOffice.name}`}
          headerInfo={{
            image: selectedOffice.img?.url,
            title: `Barry Plant ${selectedOffice.name}`,
            description: selectedOffice.address,
            id: selectedOffice.id,
          }}
        />
      )}
    </SearchResultsTabs>
  )
}

export default OfficeResults
