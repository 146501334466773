import * as S from './MediaHubSearchFrom.styled'
import Button from '@global/Button'
import Field from '@global/FormInput/Field'
import IconSearch from '@global/icon/IconSearch'
import { Formik } from 'formik'
import { Link } from '@routes'
import { Container } from '@styles/global.styled'
import { useCallback } from 'react'
import { useRouter } from 'next/router'

const normalizeUrl = (url) => url?.replace(/\/+$/, '')

const MediaHubSearchFrom = ({
  onSubmit,
  formRef,
  hideSearch = false,
  navigationItems = [],
}) => {
  const router = useRouter()
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    onSubmit && (await onSubmit(values))
    setSubmitting(false)
  }

  const items = navigationItems.filter(
    (it) => it.sub_page_count === undefined || it.sub_page_count > 0
  )

  const isTabActive = useCallback(
    (tabUrl) => {
      return normalizeUrl(router?.asPath) === normalizeUrl(tabUrl)
    },
    [router?.asPath]
  )

  return (
    <S.MediaHubSearchWrap>
      <Container className={'MediaHub-container'}>
        <S.MediaHubSearchFrom hideSearch={hideSearch}>
          <S.FilterOptions>
            {items.map((it) => (
              <Link key={it.url} route={it.url} passHref>
                <S.FilterOptionItem active={isTabActive(it.url)}>
                  <span>{!it.sub_page_count ? 'All' : it.title}</span>
                  <span>
                    {!it.sub_page_count ? 'All' : it.title.split(' ')[0]}
                  </span>
                </S.FilterOptionItem>
              </Link>
            ))}
          </S.FilterOptions>

          {!hideSearch && (
            <Formik
              innerRef={formRef}
              initialValues={{ search: '' }}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, isSubmitting, getFieldProps }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <S.FormGroup>
                    <S.SearchInput>
                      <IconSearch />
                      <Field
                        {...getFieldProps('search')}
                        className='search-input'
                        type='text'
                        placeholder='Start typing your search here'
                      />
                    </S.SearchInput>
                    <Button
                      color='secondary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      Search
                    </Button>
                  </S.FormGroup>
                </form>
              )}
            </Formik>
          )}
        </S.MediaHubSearchFrom>
      </Container>
    </S.MediaHubSearchWrap>
  )
}

export default MediaHubSearchFrom
