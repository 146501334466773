import { NextSeo } from 'next-seo'
import { useState, useEffect } from 'react'
import BlogSidebar from './BlogSidebar'
import * as S from './BlogPost.styled'
import { apiFieldsQuery, defaultImages, formSlugs } from '@lib/variables'
import { getChildPages } from '@lib/api-services/cmsService'
import { getFormsFields } from '@lib/api-services/formsService'
import { stripTags } from '@lib/helpers'
import { domain } from '@lib/variables'
import Breadcrumbs from '@global/Breadcrumbs'
import { Col, Container, Row, Section } from '@styles/global.styled'
import BlogHero from './BlogHero'
import BlogDetail from './BlogDetail'
import SignUpBanner from 'components/global/SignUpBanner'
import NewsList from '@global/Blocks/NewsList'

const BlogPost = ({
  headerImage,
  breadcrumbs,
  title,
  intro,
  sidebar,
  article,
  id,
  url,
  related,
  pageData,
  headerImagePosition,
}) => {
  // BlogPost takes child components to make up the article content.
  // This will be replaced with a WagtailBlocksSection component
  // in the BlogPage.jsx template
  let office = pageData.meta.office_ancestor
  let child_of = pageData.meta.parent.id,
    for_office = null
  if (office) {
    child_of = null
    for_office = office.id
  }

  const [relatedData, setRelatedData] = useState([])
  const [showNewsletter, setShowNewsletter] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const triggerPosition = 500 // Change this value to adjust the position of the component

      if (scrollPosition > triggerPosition) {
        setShowNewsletter(true)
      } else {
        setShowNewsletter(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getRelatedDataAsync = async () => {
    if (
      pageData.related_post_categories &&
      pageData.related_post_categories.length > 0
    ) {
      await getRelatedBy('category')
      return
    }

    if (pageData.related_post_tags && pageData.related_post_tags.length > 0) {
      await getRelatedBy('tag')
      return
    }

    setRelatedData(related)
  }

  const getRelatedBy = async (filterBy) => {
    const filterArray = getPageDataArray(filterBy)

    if (pageData && pageData.show_related_articles) {
      const totalRelated = []

      for (const filter of filterArray) {
        const filterParamBy = getFilterParamBy(filterBy, filter)
        const response = await getChildPages(
          'blog.BlogPost',
          child_of,
          6,
          1,
          {
            for_office: for_office,
            order: '-first_published_at',
            id: `!${pageData.id}`,
            ...filterParamBy,
          },
          apiFieldsQuery.BlogPost
        )

        if (response.items) {
          response.items.forEach((post) => {
            totalRelated.push({
              id: post.id,
              title: post.title,
              imgUrl:
                (post.header_image_results && post.header_image_results.url) ||
                defaultImages.mediumNavy,
              link: {
                label: 'read article',
                url: post?.meta?.path,
              },
            })
          })
        }
      }

      setRelatedData(totalRelated)
    }
  }

  const getPageDataArray = (filterBy) => {
    switch (filterBy) {
      case 'category':
        return pageData?.related_post_categories &&
          pageData.related_post_categories.length > 0
          ? pageData.related_post_categories
          : []
      case 'tag':
        return pageData?.related_post_tags &&
          pageData.related_post_tags.length > 0
          ? pageData.related_post_tags
          : []
      default:
        return []
    }
  }

  const getFilterParamBy = (filterBy, filter) => {
    return filterBy === 'category'
      ? { categories__category: filter.category_id }
      : { tags: filter }
  }

  useEffect(() => {
    getRelatedDataAsync()
  }, [])

  const firstParagraph = article.find((a) => {
    return a.type === 'paragraph'
  })
  const canonicalURL = `${domain}${pageData.meta.path || ''}`
  const metaTitle = pageData.meta.meta_title
  const ogTitle = pageData.meta.og_title

  const categoriesOrder =
    pageData.categories && pageData.categories.sort((a, b) => a.id - b.id)
  const category =
    categoriesOrder.find((item) => item.id === categoriesOrder[0].id) || null

  return (
    <S.BlogPostWrap>
      <NextSeo
        title={metaTitle ? metaTitle : title}
        canonical={canonicalURL}
        url={canonicalURL}
        openGraph={{
          images: pageData.header_image_results
            ? [pageData.header_image_results]
            : [defaultImages.mediumNavy],
          title: ogTitle ? ogTitle : title,
          description: firstParagraph ? stripTags(firstParagraph.value) : '',
          url: canonicalURL,
        }}
      />

      <Container>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {headerImage && (
          <BlogHero
            imageUrl={headerImage}
            maxHeight={'600px'}
            imagePosition={headerImagePosition}
          />
        )}
      </Container>

      <Section>
        <Container>
          <Row gutter={15}>
            <Col lg={8}>
              <BlogDetail
                title={title}
                intro={intro}
                article={article}
                sideBar={
                  <BlogSidebar
                    {...sidebar}
                    headerImage={headerImage}
                    id={id}
                    url={url}
                    title={title}
                    categoryData={category}
                  />
                }
              />
            </Col>
            <Col lg={4} className={'col-sidebar'}>
              <BlogSidebar
                {...sidebar}
                headerImage={headerImage}
                id={id}
                url={url}
                title={title}
                categoryData={category}
              />

              {showNewsletter && (
                <S.BlogPostSidebarNewsletterWrap>
                  <SignUpBanner smallMode={true} />
                </S.BlogPostSidebarNewsletterWrap>
              )}
            </Col>
          </Row>
        </Container>
      </Section>

      <S.BlogPostNewsletterWrap>
        <Container>
          <SignUpBanner smallMode={true} />
        </Container>
      </S.BlogPostNewsletterWrap>

      {relatedData && relatedData.length ? (
        <S.BlogPostRelatedPost>
          <NewsList
            title={'Related Articles'}
            articles={relatedData}
            number_of_rows={1}
            style={{ marginBottom: '0px' }}
          />
        </S.BlogPostRelatedPost>
      ) : null}
    </S.BlogPostWrap>
  )
}

BlogPost.defaultProps = {
  headerImage: '/static/img/defaults/props/media-banner.jpg',
  article: [],
}
BlogPost.getInitialProps = async (ctx, pageData) => {
  // related articles
  let related = []
  let office = pageData.meta.office_ancestor
  let child_of = pageData.meta.parent.id,
    for_office = null
  if (office) {
    child_of = null
    for_office = office.id
  }
  const category =
    (pageData.categories &&
      pageData.categories.length &&
      pageData.categories[0].category_id) ||
    ''
  if (pageData.show_related_articles) {
    const resp = await getChildPages(
      'blog.BlogPost',
      child_of,
      6,
      1,
      {
        for_office: for_office,
        order: '-first_published_at',
        categories__category: category,
        id: `!${pageData.id}`,
      },
      apiFieldsQuery.BlogPost
    )

    if (resp.items) {
      related = resp.items.map((post) => {
        return {
          id: post.id,
          title: post.title,
          imgUrl:
            (post.header_image_results && post.header_image_results.url) ||
            defaultImages.mediumNavy,
          link: {
            label: 'read article',
            url: post?.meta?.path,
          },
        }
      })
    }
  }

  // Signup Form
  let signUpForm = await getFormsFields(
    formSlugs.emailSignup,
    'u-5/12@tablet u-3/12@desktop u-4/12@wide',
    'c-sign-up-banner__submit u-4/12@desktop u-3/12@wide'
  )
  return {
    signUpForm: {
      title: 'Subscribe for email alerts',
      copy: 'Get Barry Plant Media Hub news, blog articles and resources straight to your inbox',
      ...signUpForm,
    },
    related: related,
    id: pageData.id,
    url: pageData.meta && pageData.meta.detail_url,
    title: pageData.title,
    headerImage: pageData.header_image && pageData.header_image.url,
    headerImagePosition: pageData.hero_image_anchor || 'top',
    intro: pageData.preamble || '',
    sidebar: {
      showDate: pageData.show_date,
      postedDate: pageData.date,
      authors: pageData.authors,
      author: pageData.author
        ? {
            name: pageData.author.name,
            nameShort: pageData.author.name_short,
            title: pageData.author && pageData.author.title,
            location:
              pageData.author.offices &&
              pageData.author.offices[0] &&
              pageData.author.offices[0].office.suburb,
            image:
              (pageData.author.profile_image_thumbnail &&
                pageData.author.profile_image_thumbnail.url) ||
              (pageData.author.profile_image &&
                pageData.author.profile_image.url),
          }
        : {},
    },
    article: pageData.article,
    breadcrumbs: [
      {
        label: pageData.meta.parent.title,
        url: pageData.meta.parent_path,
      },
      {
        label: pageData.title,
        url: pageData.meta.path,
      },
    ],
    pageData: pageData,
  }
}
export default BlogPost
