import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import HeroSection from '@global/Blocks/Hero'
import { getOffices } from '@lib/api-services/officesService'
import dynamic from 'next/dynamic'

const MaintenanceRequestForm = dynamic(
  () => import('@global/Form/MaintenanceRequestForm'),
  {
    ssr: false,
  }
)

const MaintenanceRequestPage = ({ pageData, office, offices }) => {
  return (
    <>
      {pageData?.hero_image && (
        <HeroSection
          title={pageData.title}
          titlePosition='bottom'
          imagePosition={pageData.hero_image_anchor}
          bgImageUrl={pageData.hero_image?.url}
        />
      )}
      <MaintenanceRequestForm office={office} offices={offices} />

      {pageData && pageData.content_blocks && (
        <WagtailBlocksSection blocks={pageData.content_blocks} />
      )}
    </>
  )
}

MaintenanceRequestPage.getInitialProps = async (ctx, pageData) => {
  let offices = []
  await getOffices({ per_page: 500, fields: 'id,title' }).then((resp) => {
    offices =
      resp.results &&
      resp.results.map((opt) => {
        return {
          value: opt.id,
          label: opt.title,
        }
      })
  })
  return {
    pageData,
    offices,
    office: pageData.meta && pageData.meta.office_ancestor,
  }
}
export default MaintenanceRequestPage
